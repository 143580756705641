@layer base {
  :root {
    /* #5b1f67 */
    --primary: 91 31 103;
    --primary-dark: #381340;
    --primary-light: #84578d;
    --primary-texttransparent: #ffffffa6;

    /* #9d7692 */
    --secondary: 157 118 146;
    --secondary-dark: #62495b;
    --secondary-light: #b598ad;
    --secondary-constrasttext: #ffffff;

    --sustainability-constrasttext: #ffffff;

    --sale: #bc096d;

    --grey: #b9b9b9;
    --grey-light: #f4f3f2;
    --grey-light-dark: rgb(185, 185, 185);
    --grey-dark: #444444;

    --linear-progress-background: #c0a9c5;

    --text-disabled: #4e5051;
    --text-primary: #000000;
    --text-secondary: #000000a6;
    --text-dark: #000000;
    --text-darktransparent: #000000a6;
    --text-light: #ffffff;
    --text-lightTransparent: #ffffffa6;

    --border-sm: 3px;
    --border-default: 3px;
    --border-md: 12px;
    --border-lg: 16px;
    --border-xl: 20px;
  }
}

@layer components {
  .price-old-price {
    text-decoration: none;
  }

  .page-logo {
    @apply xl:h-[29px];
  }
  .scroll-to-top-button {
    bottom: 5rem;
    }

  .button {
    @apply rounded;
  }

  .pagination-item {
    @apply rounded;
  }

  .toggle-group-item {
    @apply first:rounded-l last:rounded-r;
  }
}
